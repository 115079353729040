import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import {
  ref,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import { storage } from "./firebase";
import CardMedia from '@mui/material/CardMedia';
import { Slide } from 'react-awesome-reveal';

function AutoPlay() {

  const [images, setImages] = useState([]);

  //const storageRef = ref(storage, "nb/");

  useEffect(() => {
    const fetchData = async () => {



      // Assuming you have multiple files in the folder
      const listRef = ref(storage, "nb/");

      const list = await listAll(listRef);

      const imageUrls = await Promise.all(
        list.items.map((itemRef) => getDownloadURL(itemRef))
      );

      setImages(imageUrls);

    };

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };
  return (
    <div className="slider-container">


      <Slide direction="left">
        <div className="App">
          <h1>Bulletin Boad & Announcement </h1>
        </div>
      </Slide>

      <Slider {...settings}>
        {images.map((imageUrl, index) => (
          <div key={index}>
            <div className="img-body">
              <CardMedia
                component="img"
                alt="Notice"
                height="140"
                width="80"
                image={imageUrl}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default AutoPlay;
