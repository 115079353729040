import Slider from 'react-slick';
import './multipleItems.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Marquee from 'react-fast-marquee';
import logo from "../src/WhatsApp.png";
import "./index.css";

const Fade = () => {
    const settings = {
        
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        pauseOnHover: false
      };
    return (
      <>
        

        <div className="content">    
        <h1 className="header"><Marquee direction="left" speed={50} delay={5} pauseOnHover={true}>Think & Implement</Marquee></h1>
        </div>

        <Slider {...settings}>
        
          <div className='slider slider-1'>
          <div className='slider-text'>
          {/*<h4>Booked Now </h4>
          <h1>Next-gen speed unveiled</h1>*/}
          </div>
          </div>

          <div className='slider slider-2'>
          <div className='slider-text'>
         {/*<h4>Booked Now </h4>
          <h1>Next-gen speed unveiled</h1>*/}
          </div>
          </div>

          <div className='slider slider-3'>
          <div className='slider-text'>
            {/*<h4>Booked Now </h4>
          <h1>Next-gen speed unveiled</h1>*/}
          </div>
          </div>

          <div className='slider slider-4'>
          <div className='slider-text'>
        {/*<h4>Booked Now </h4>
          <h1>Next-gen speed unveiled</h1>*/}
          </div>
          </div>

          <div className='slider slider-5'>
          <div className='slider-text'>
          {/*<h4>Booked Now </h4>
          <h1>Next-gen speed unveiled</h1>*/}
          </div>
          </div>

          <div className='slider slider-6'>
          <div className='slider-text'>
          {/*<h4>Booked Now </h4>
          <h1>Next-gen speed unveiled</h1>*/}
          </div>
          </div>
          <div className='slider slider-7'>
          <div className='slider-text'>
          {/*<h4>Booked Now </h4>
          <h1>Next-gen speed unveiled</h1>*/}
          </div>
          </div>

          <div className='slider slider-8'>
          <div className='slider-text'>
          {/*<h4>Booked Now </h4>
          <h1>Next-gen speed unveiled</h1>*/}
          </div>
          </div>

          <div className='slider slider-9'>
          <div className='slider-text'>
          {/*<h4>Booked Now </h4>
          <h1>Next-gen speed unveiled</h1>*/}
          </div>
          </div>

          <div className='slider slider-10'>
          <div className='slider-text'>
          {/*<h4>Booked Now </h4>
          <h1>Next-gen speed unveiled</h1>*/}
          </div>
          </div>
          <div className='slider slider-11'>
          <div className='slider-text'>
          {/*<h4>Booked Now </h4>
          <h1>Next-gen speed unveiled</h1>*/}
          </div>
          </div>
          <div className='slider slider-12'>
          <div className='slider-text'>
          {/*<h4>Booked Now </h4>
          <h1>Next-gen speed unveiled</h1>*/}
          </div>
          </div>
          <div className='slider slider-13'>
          <div className='slider-text'>
          {/*<h4>Booked Now </h4>
          <h1>Next-gen speed unveiled</h1>*/}
          </div>
          </div>
        </Slider>
    
   
   
        {/* WHATSAPP INTEGRATION */}
  <div className="whatsapp_float">   
  <a href="https://wa.me/917002922525" target="_blank" rel="noreferrer">
  <img src={logo} width="50px" />
  </a> 
  </div>
  </>
   );
};

export default Fade;