import React, { useState } from "react";
import {
  AppBar,
  Button,  
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DrawerComp from "./Drawer";
import "./Review.css";
import Footer from "./Footer";
import "./AlumniRegForm.css";




const Logout = () => {

  const [value, setValue] = useState();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));


  return (

    <React.Fragment>
      <AppBar sx={{ background: "#063970" }}>
        <Toolbar>
          <img src="images/orkidicon.png" alt="" sx={{ transform: "scale(2)" }} />
          Orkid Software Services
          {isMatch ? (
            <>
              <Typography sx={{ fontSize: "1.1rem", paddingLeft: "15%" }}>
              </Typography>
              <DrawerComp />
            </>
          ) : (
            <>
              <Tabs
                sx={{ marginLeft: "auto" }}
                indicatorColor="secondary"
                textColor="inherit"
                value={value}
                onChange={(e, value) => setValue(value)}
              >
              </Tabs>


              <Button sx={{ marginLeft: "auto" }} variant="contained" >
                Alumni Registration
              </Button>
              <Button sx={{ marginLeft: "20px" }} variant="contained">
                Notice Board
              </Button>
              <Button sx={{ marginLeft: "20px" }} variant="contained">
                Images & Gallery
              </Button>


            </>
          )}
        </Toolbar>
      </AppBar>



      <div className="App">
        <h1> Images & Gallery Dashboard </h1>
      </div>
      <div className="space" >
        Copyright ©2024 Orkid Software Services
        <Footer />
      </div>





    </React.Fragment>



  );


};



export default Logout;