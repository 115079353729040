import React, { useState } from "react";

import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";


const pages = ["Alumni Registration","Notice Board","Images & Gallery"];
const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useNavigate();

  const handleclick = (index) =>{
    setOpenDrawer(!openDrawer);
    console.log(index);
    if (index === 0) {
      history("/Alumni");
    } else if (index === 1) {
      history("/Login");
    } else if (index === 2) {
      history("/Logout");
    } 

  };
    
  


 

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}     
      >
        <List>
          {pages.map((page, index) => (
          
            <ListItemButton key={index}>
              <ListItemIcon>
                <ListItemText onClick={() => handleclick(index)}>{page}</ListItemText>
              </ListItemIcon>
            </ListItemButton>
           
          ))}
        </List>

      </Drawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >    
        <MenuIcon color="blue" />
      </IconButton>
    </React.Fragment>
    
  );


};

export default DrawerComp;