import React, { useState,useEffect } from "react";
import {
  AppBar,
  Button,  
  Tabs,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DrawerComp from "./Drawer";
import Footer from "./Footer";
//import "./AlumniRegForm.css";
import Typography from "@mui/material/Typography";
import { v4 } from "uuid";

import { ref, 
  uploadBytes,
  getDownloadURL,
  listAll,
  getStorage,
 } from "firebase/storage";

import { storage } from "./firebase";

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import { Document, Page } from 'react-pdf';

{/* new code */}





const Login = () => {

  const [value, setValue] = useState();
  const theme = useTheme(); 
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

 
  const [imageUpload, setImageUpload] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);


  const storageRef = ref(storage, "nb/");

  

  const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `nb/${imageUpload.name+ v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      alert("Uploaded succesfully");
     
    });
  };

  { /*  new code */}

  const handleNoticeSelect = async (notice) => {
    setSelectedNotice(notice);
  
    if (notice) { 
      try {      
              const storageRef = ref(storage, notice); 
              const url = await getDownloadURL(storageRef);  
             window.open(url, '_blank', 'noopener,noreferrer'); //open the url in new window
      } catch (error) {
        console.error("Error getting PDF URL:", error);
      }
    } else {
      setPdfUrl(null); // Clear PDF URL if no URL exists
    
    }
  };

  {selectedNotice && (
    <div>
      <h2>Selected Notice:</h2>
      <h3>{selectedNotice}</h3>
      {pdfUrl ? (
        <Document file={pdfUrl}>
          <Page pageNumber={1} /> 
        </Document>
      ) : (
        <p>No PDF associated with this notice.</p>
      )}
    </div>
  )}

  { /*  new code */}

  useEffect(() => {
    const fetchData = async () => {
   
     

      // Assuming you have multiple files in the folder
      const listRef = ref(storage, "nb/");
      
      const list = await listAll(listRef);

      const imageUrls = await Promise.all(
        list.items.map((itemRef) => getDownloadURL(itemRef))
      );

      setImages(imageUrls);
 
    };

    fetchData();
  }, []);


  return (   
      
    <React.Fragment>
    <AppBar sx={{ background: "#063970" }}>
      <Toolbar>
       <img src="images/orkidicon.png" alt=""  sx={{ transform: "scale(2)"}}  /> 
              Orkid Software Services
        {isMatch ? (
          <>
            <Typography sx={{ fontSize: "1.1rem", paddingLeft: "15%" }}>             
            </Typography>             
            <DrawerComp />
          </>
        ) : (
          <>
            <Tabs
              sx={{ marginLeft: "auto" }}
              indicatorColor="secondary"
              textColor="inherit"
              value={value}
              onChange={(e, value) => setValue(value)}
            >            
            </Tabs>
            
            <Button sx={{ marginLeft: "auto" }} variant="contained" >
            Alumni Registration
            </Button>
            <Button sx={{ marginLeft: "20px" }} variant="contained">
            Notice Board 
            </Button>
            <Button sx={{ marginLeft: "20px" }} variant="contained">
            Contactus Dashboard
            </Button>
           
          </>
        )}
      </Toolbar>
      </AppBar>

     
    
      <div className="App">
      <h1> Notice Board </h1>
    
      <input
        type="file"
        onChange={(event) => {
          setImageUpload(event.target.files[0]);
        }}
      />
      <button onClick={uploadFile}> Upload Image</button>
     
  {images.length > 0 && (
<Container maxWidth="lg">
<Typography variant='h4' align='center' style={{ marginTop: "100px" }}>
 Notice Boad
</Typography>
<Grid container spacing={2}>
      {images.map((imageUrl, index) => (
        <Grid item key={index}>
          <Card>
            <CardMedia
              component="img"
              alt="Notice"
              height="140"
              image={imageUrl}               
             />      
             <button key={index} onClick={() => handleNoticeSelect(imageUrl)}> View </button>       
          </Card>      
        </Grid>
      ))}
    </Grid>
</Container>
)}
      </div>    
      
      
      <div className="space" >
        Copyright ©2024 Orkid Software Services
        <Footer />
      </div>
 

  
  </React.Fragment>

  
    
  );
 
  
};



export default Login;