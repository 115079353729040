import React from 'react';
import Marquee from 'react-fast-marquee';
import "./Review.css";
import img1 from './pic/1.jpg';
import img2 from './pic/2.jpg';
import img3 from './pic/3.jpg';
import img4 from './pic/4.jpg';
import img5 from './pic/5.jpg';
import img6 from './pic/6.jpg';
import img7 from './pic/7.png';
import img8 from './pic/8.jpg';
import img9 from './pic/9.jpg';
import img10 from './pic/10.jpg';
import './App.css';


function Review() {
  return (
    <>
    <div className="App">
      <div className="title">
        <h1>Awards & Accolades</h1>
      </div>

      <div>
        <Marquee direction="right" speed={100} delay={5} pauseOnHover={true}>
          <div className="image_wrapper">
            <img src={img1} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img2} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img3} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img4} alt="" />
          </div>
          <div>
            <img src={img5} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img6} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img7} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img8} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img9} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img10} alt="" />
          </div>
        </Marquee>
      </div>
    </div>
   
     </>
  );
 
}

export default Review;